import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/turborepo-the-next-big-thing-in-build-systems",
  "date": "2022-01-01",
  "title": "Turborepo: The Next Big Thing in Build Systems",
  "author": "Sean Amarasinghe",
  "tags": ["development", "javascript", "nodejs"],
  "featuredImage": "feature.jpg",
  "excerpt": "Turborepo is a high-performance build system for JavaScript and TypeScript codebases. Vercel who acquired Turborepo claims, through incremental builds, intelligent remote caching, and optimized task scheduling, Turborepo can boost build speeds by 85% or more, enabling teams of all sizes to maintain a fast and efficient build system that scales as codebases and teams grow."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What is a monorepo?`}</h2>
    <p>{`A monorepo (mono repository) is a single repository that stores all of your code and assets for every project. These include your frontend as well as backend code.`}</p>
    <p>{`The benfits of a monorepo are:`}</p>
    <ul>
      <li parentName="ul">{`A single source of truth. (e.g. dependency management with a single package.json file, tests and configs)`}</li>
      <li parentName="ul">{`Share and reuse code easily.`}</li>
      <li parentName="ul">{`Visibility to manage dependencies (e.g., if you make a change, what else will be impacted?).`}</li>
      <li parentName="ul">{`To make atomic changes (e.g., one operation to make a change across multiple projects).`}</li>
      <li parentName="ul">{`Ease of teams to collaborate more.`}</li>
      <li parentName="ul">{`For making large-scale changes (e.g., code refactoring).`}</li>
    </ul>
    <p>{`This seems to be the trend with companies like Google and Facebook.`}</p>
    <h2>{`What is Turborepo?`}</h2>
    <p>{`Monorepos are incredible for productivity, especially on the frontend, but the tooling can be a nightmare. There’s a lot of stuff to do (and things to mess up). Nothing “just works.” It’s become completely normal to waste entire days or weeks on plumbing — tweaking configs, writing one-off scripts, and stitching stuff together.`}</p>
    <p>{`According to Jared Palmer the founder of Turborepo, it is a fresh take on the whole tooling setup. Designed to glue everything together. A toolchain with sensible defaults, but even better escape hatches. Built with the same techniques used by the big guys, but in a way that doesn’t require staff to maintain.`}</p>
    <p>{`Turborepo is a high-performance build system for JavaScript and TypeScript codebases. Vercel who acquired Turborepo claims, through incremental builds, intelligent remote caching, and optimized task scheduling, Turborepo can boost build speeds by 85% or more, enabling teams of all sizes to maintain a fast and efficient build system that scales as codebases and teams grow.`}</p>
    <h2>{`What does Turborepo offer?`}</h2>
    <p>{`Turborepo reimagines build system techniques used by Facebook and Google to remove maintenance burden and overhead.`}</p>
    <h3>{`Incremental builds (cache artifacts)`}</h3>
    <p>{`By skipping the artifacts that have already been computed by building once, Turborepo will remember what you’ve built.`}</p>
    <h3>{`Content-aware hashing (contents not timestamps)`}</h3>
    <p>{`Turborepo looks at the contents of your files, not timestamps to figure out what needs to be built.`}</p>
    <h3>{`Remote Caching (Share build cache)`}</h3>
    <p>{`Share a remote build cache with your teammates and CI/CD for even faster builds.`}</p>
    <h3>{`Parallel execution (Maximise CPU usage)`}</h3>
    <p>{`Execute builds using every core at maximum parallelism without wasting idle CPUs.`}</p>
    <h3>{`Zero runtime overhead (Won’t interfere with your code)`}</h3>
    <p>{`Turborepo won’t interfere with your runtime code or touch your sourcemaps.`}</p>
    <h3>{`Pruned subsets (Speed up PaaS)`}</h3>
    <p>{`Speed up PaaS (Platform as a Service) deploys by generating a subset of your monorepo with only what’s needed to build a specific target.`}</p>
    <h3>{`Task pipelines (Task relationships)`}</h3>
    <p>{`Define the relationships between your tasks and then let Turborepo optimize what to build and when.`}</p>
    <h3>{`Profile in your browser (Generate build profiles)`}</h3>
    <p>{`Generate build profiles and import them in Chrome or Edge to understand which tasks are taking the longest.`}</p>
    <h2>{`Getting Started`}</h2>
    <h3>{`Brand new monorepo`}</h3>
    <p>{`If you’re starting a brand new monorepo, you can get started with a single command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`npx create-turbo@latest
`}</code></pre>
    <p>{`Follow the prompts to bootstrap a brand new Turborepo.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2022-01-01-turborepo-the-next-big-thing-in-build-systems/bootstrap-new.png",
        "alt": "Bootstrap a brand new Turborepo"
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`yarn add turbo -W --dev
`}</code></pre>
    <p>{`Exisiting monorepo`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`yarn add turbo -W --dev
`}</code></pre>
    <p>{`Or`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`npm install turbo -D
`}</code></pre>
    <p>{`The turbo package is a little shell that will install the proper `}<inlineCode parentName="p">{`@turborepo/*`}</inlineCode>{` packages.`}</p>
    <h3>{`Add turbo to package.json`}</h3>
    <p>{`In your root `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`, add a key `}<inlineCode parentName="p">{`turbo`}</inlineCode>{`. If your git repo's base branch is NOT origin/master then you need to specify a baseBranch too (for example, ours is set to origin/main).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`{
  "turbo": {
    "baseBranch": "origin/main"
  }
}
`}</code></pre>
    <h3>{`Create a pipeline`}</h3>
    <p>{`In your package.json, add the commands you want to "turbocharge" to your pipeline.`}</p>
    <p>{`Your pipeline both defines the way in which your NPM package.json scripts relate to each other, and configures cache artifacts for those scripts. These relationships and cache settings are then fanned out and applied to all package tasks across your entire monorepo.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`{
  "turbo": {
    "baseBranch": "origin/main",
    "pipeline": {
      "build": {
        "dependsOn": ["^build"],
        "outputs": [".next/**"]
      },
      "test": {
        "dependsOn": ["^build"],
        "outputs": []
      },
      "lint": {
        "outputs": []
      },
      "dev": {
        "cache": false
      }
    }
  }
}
`}</code></pre>
    <p>{`In the above example, the build and test tasks are dependent on their packages dependencies and devDependencies being built first, this is denoted with the ^ prefix.`}</p>
    <p>{`For each script in each workspace’s package.json, Turborepo will cache files outputted to `}<inlineCode parentName="p">{`dist/`}</inlineCode>{` and `}<inlineCode parentName="p">{`build/`}</inlineCode>{` folders by default if an override isn't added. Using the `}<a parentName="p" {...{
        "href": "https://turborepo.org/docs/features/caching#configuring-cache-outputs-1"
      }}>{`outputs`}</a>{` array allows you to override the default cache folders, like in the example above, where the `}<inlineCode parentName="p">{`.next/`}</inlineCode>{` folder is selected to be the default cache folder for the build task. Turborepo will automatically record and cache logs to `}<inlineCode parentName="p">{`.turbo/turbo-<script>.log`}</inlineCode>{` for you, so you don't ever need to specify that in the outputs array.`}</p>
    <p>{`Finally, the dev task has its caching disabled using the cache key with a value of false.`}</p>
    <p>{`See the pipeline documentation for more details on how to configure your pipeline.`}</p>
    <h3>{`Run`}</h3>
    <p>{`To build with your freshly installed turbo, type the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`yarn turbo run build
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/images/2022-01-01-turborepo-the-next-big-thing-in-build-systems/turbo-run-build.png",
        "alt": "Run"
      }}></img></p>
    <p>{`Once it’s built, you can run the apps inside your repo:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh-pr"
      }}>{`yarn dev
`}</code></pre>
    <h2>{`Summary`}</h2>
    <p>{`Turborepo significantly increases build speeds as well as serves as an abstraction of the declarative build pipeline, easy to use config options, profiling and debugging options and documentation.`}</p>
    <p>{`Turborepo would most certainly change the minds of people who hesitated the monorepo approach for building apps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      